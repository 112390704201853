import { Component, HostListener, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Language } from "../models/extra-service.model";
import { UserService } from "../services/user.service";
import { ISidebarElement, SidebarItems } from "./header-configuration";
import { environment } from "src/environments/environment";
import { distinctUntilChanged, filter } from "rxjs";
import { AuthService } from "@auth0/auth0-angular";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  visibleSidebar1;
  sidebarVisible2;
  user: any;
  lead: string = "";
  isAdmin = false;
  title: string;
  @Input() hideBreadcrumb = "false";
  rute: string;
  languageList: Array<Language>;
  languageSelected: Language;
  lang: string;
  sidebarItemList: ISidebarElement[] = SidebarItems;
  environment: boolean;
  userProfileSubMenu: boolean = false;
  userPhoto: any;
  @Input() page: string;
  backgroundColor: string = "";
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _translateService: TranslateService,
    private userService: UserService,
    private authService: AuthService,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  ngOnInit() {
    this.environment = environment.production;
    this.title = this.activatedRoute.snapshot.data.title;
    this.getLanguages();
    this.lang = localStorage.getItem("lang");
    this.userService.getUsuario().subscribe(resp => {
      this.user = resp;
    });

    this.authService.user$.subscribe(user =>{
      this.isAdmin = this.userService.isUserAdmin(user);
    });
    
    this.getUserPhoto();
    this.changeBackground(this.router.url)
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        distinctUntilChanged()
      )
      .subscribe((event: NavigationEnd) => {
        this.changeBackground(event.urlAfterRedirects);
      });
  }

  changeBackground(currentRoute: string) {
    currentRoute = currentRoute.slice(3);
    if ( currentRoute == ("") || currentRoute == ("/flights")) {
      this.backgroundColor = "transparent";
    } else {
      this.backgroundColor = "#fff";
    }
  }

  getUserPhoto(): void {
    this.userService.getUserPhoto()
      .subscribe(data => {
        const blob = new Blob([data]);
        if (blob.size > 0) {
          this.userPhoto = URL.createObjectURL(blob);
        } else {
          this.userPhoto = '../../../assets/images/user-profile.png';
        }
      });
  }

  hasActiveSubItem(subElementList: any[]): boolean {
    for (const subItem of subElementList) {
       if (this.router.isActive('/' + this.lang + '/' + subItem.route, true)) {
          return true;
       }
    }
    return false;
  }

  onRouterLink(route: string) {
    if (route) {
      const linkUrl = `/${this.lang}/${route}`;
      this.router.navigate([linkUrl]);
    }
  }

  logout() {
    this.authService.logout({ logoutParams: { returnTo: this.doc.location.origin } });
    localStorage.clear();
    sessionStorage.clear();
  }

  getLanguages() {
    this.userService.getAllLanguages().subscribe(data => {
      this.languageList = data;
      this.getLead();
    });
  }
  getLead(){
    this.userService.getLead().subscribe(data => {
      if (data?.lead?.firstName != null && data?.lead?.lastName != null) {
        this.lead = data?.lead.firstName + " " + data?.lead.lastName;
      } else {
        this.lead = "";
      }
      if (!this.userService.manualLanguage) this.languageSelected = this.languageList.find(f=> f.name.toLocaleUpperCase() == data.language.name.toLocaleUpperCase());
      else this.languageSelected = this.languageList.find(f=> f.name.toLocaleUpperCase() == this._translateService.currentLang.toLocaleUpperCase());
      this.rute = this.router.url.split("/")[3];
      this.changeLanguage();
    });
  }

  changeLanguage() {
    let currentUrl = this.router.url.replace(this.lang.toLocaleLowerCase(), this.languageSelected.name.toLocaleLowerCase());
    localStorage.setItem("lang", this.languageSelected.name.toLocaleLowerCase());
    this.lang = localStorage.getItem("lang");
    this.userService.manualLanguage = true;
    this.router.navigate([currentUrl]);
  }
  closeSidebar() {
    this.visibleSidebar1 = false;
  }

  @ViewChild("userProfileSubMenuModal") userProfileSubMenuModal;
  @HostListener("document:click", ["$event.target"])
  onClick(targetElement) {
    if (!this.userProfileSubMenuModal.nativeElement.contains(event.target) &&
        event.target !== this.userProfileSubMenuModal.nativeElement && document.getElementById("userProfileSubMenuModal")) {
      document.getElementById("userProfileSubMenuModal").style.display = "none";
    }
  }
}
